'use client';

import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import BookDemoModal from '@/modals/BookDemoModal/BookDemoModal';

export function Modals() {
  const { isModalOpened, setIsModalOpened } = useNewsletterModalContext();
  if (!isModalOpened) {
    return null;
  }
  return <BookDemoModal onClose={() => setIsModalOpened(false)} />;
}
