'use client';

import { PropsWithChildren, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import Accordion from '@/components/Accordeon/Accordeon';
import Button from '@/components/Button/Button';
import OriginalDrawer from '@/components/Drawer/Drawer';
import Link from '@/components/Link/Link';
import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import ClientOnly from '@/helpers/ClientOnly';
import { CloseIcon } from '@/public/icons';
import { LogoSvg } from '@/public/icons';
import { devices } from '@/styles/breakpoints';
import { NavItems } from '@/types/navbar';
import { IRoutePath } from '@/types/router';

type NavigationDrawerProps = PropsWithChildren<{ items: NavItems }>;

export default function NavbarDrawer({
  children,
  items,
}: NavigationDrawerProps) {
  const { setIsModalOpened } = useNewsletterModalContext();
  const [activeIndex, setActiveIndex] = useState(0);
  const [nestedDrawerOpen, setNestedDrawerOpen] = useState(false);

  const handleChangeScreen = (index) => {
    setActiveIndex(index);
    setNestedDrawerOpen(!nestedDrawerOpen);
  };

  return (
    <OriginalDrawer.Drawer>
      {children}
      <Wrapper>
        <ClientOnly>
          <OriginalDrawer.Target
            openClass="drawer-opened"
            closedClass="drawer-closed"
          >
            <div className="my-drawer">
              <div className="my-drawer-container">
                <Heading>
                  <DrawerCloseButton />
                  <LogoSvg color="#324058" />
                </Heading>

                <NavItemsList
                  handleChangeScreen={handleChangeScreen}
                  nestedDrawerOpen={nestedDrawerOpen}
                  activeIndex={activeIndex}
                  items={items}
                />

                <ButtonWrapper>
                  <Button
                    onClick={() => setIsModalOpened(true)}
                    fullWidth={true}
                    variant="secondary"
                  >
                    <span>Sign up</span>
                  </Button>
                  <Button
                    onClick={() =>
                      window.open('https://app.orcatec.com/', 'e_blank')
                    }
                    fullWidth={true}
                    color="blue"
                    variant="outlined"
                  >
                    <span>Sign in</span>
                  </Button>
                </ButtonWrapper>
              </div>
            </div>
          </OriginalDrawer.Target>
        </ClientOnly>
      </Wrapper>
    </OriginalDrawer.Drawer>
  );
}

function NavItemsList({ items }: NavigationDrawerProps) {
  const { close } = OriginalDrawer.useDrawer();
  const path = usePathname();
  return (
    <NavItemList>
      {items.map((singleItem, idx) => {
        const isHasSubMenu = !!singleItem?.sub_menu?.length;

        return (
          <NavItem key={idx}>
            {isHasSubMenu ? (
              <Accordion
                iconStyles={{ height: '40px', width: '40px' }}
                titleStyles={{ padding: 0 }}
                key={singleItem.title}
                isNavbar={true}
                descriptionStyle={{ width: '100%' }}
                title={
                  <NavItemLink $isactive={path?.includes(singleItem.href)}>
                    {singleItem.title}
                  </NavItemLink>
                }
              >
                <NestedListItems style={{ paddingRight: '0px' }}>
                  {singleItem?.sub_menu?.map((item, idx) =>
                    !item?.items.length ? (
                      <NestedItem $isactive={path === item.href} key={idx}>
                        <Link
                          onClick={() => {
                            close();
                          }}
                          key={idx}
                          href={item.href || '/'}
                        >
                          {item.title}
                        </Link>
                      </NestedItem>
                    ) : (
                      <Accordion
                        iconStyles={{ height: '40px', width: '40px' }}
                        titleStyles={{ padding: 0, marginTop: '10px' }}
                        key={item.title}
                        isNavbar={true}
                        title={
                          <NavItemLink
                            $isactive={
                              item?.items
                                .map((item) => item.href)
                                .includes(path) &&
                              item.href !== IRoutePath.FEATURES
                            }
                          >
                            {item.title}
                          </NavItemLink>
                        }
                      >
                        <NestedListItems style={{ paddingRight: '0px' }}>
                          {item?.items?.map((item, idx) => (
                            <NestedItem
                              $isactive={path === item.href}
                              key={idx}
                            >
                              <Link
                                onClick={() => {
                                  close();
                                }}
                                key={idx}
                                href={item.href || '/'}
                              >
                                {item.title}
                              </Link>
                            </NestedItem>
                          ))}
                        </NestedListItems>
                      </Accordion>
                    )
                  )}
                </NestedListItems>
              </Accordion>
            ) : (
              <Link onClick={close} href={singleItem.href}>
                <NavItemLink
                  $isactive={singleItem.href === path}
                  onClick={close}
                >
                  <span>{singleItem.title}</span>
                </NavItemLink>
              </Link>
            )}
          </NavItem>
        );
      })}
    </NavItemList>
  );
}

function DrawerCloseButton() {
  const ref = useRef(null);
  const a11yProps = OriginalDrawer.useA11yCloseButton(ref);

  return (
    <CloseIcon
      style={{
        color: 'black',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      width={14}
      height={14}
      className="close-icon"
      _ref={ref}
      {...a11yProps}
      aria-expanded={false}
    />
  );
}

const Wrapper = styled.div`
  .my-drawer {
    width: 100%;
    height: 100%;
    z-index: var(--zDrawer);
    background: white;
    transition: margin-left 0.3s cubic-bezier(0.82, 0.085, 0.395, 0.895);
    overflow: scroll;

    @media only screen and ${devices.tablet} {
      width: 50%;
    }
  }

  .my-drawer-container {
    grid-template-rows: auto 1fr;
    position: relative;
    height: 100%;
    margin: auto;
    max-width: 70rem;
    padding: 0 16px;
    display: grid;
    overflow: scroll;
  }

  .drawer-closed {
    margin-left: -100%;
  }

  .drawer-opened {
    margin-left: 0;
  }
`;

const NavItemList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;

  padding-left: 10px;
`;

const NavItem = styled.li`
  padding: 12px 0px 0px;
`;
const NavItemLink = styled.div<{ $isactive: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => (props.$isactive ? 'var(--mainBlue)' : 'black')};
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: start;
  padding: 10px 0 0 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0px;
`;

// const NestedListWrapper = styled.div`
//   padding: 0 16px;
//   & .collapsed-icon {
//     width: 40px;
//     height: 40px;
//     background-color: transparent;
//     & svg {
//       color: rgba(77, 77, 77, 1);
//       width: 20px;
//       height: 20px;
//       fill: rgba(77, 77, 77, 1);
//       & path {
//         color: rgba(77, 77, 77, 1);
//         fill: rgba(77, 77, 77, 1);
//       }
//     }
//   }
// `;

const NestedListItems = styled.ul`
  padding: 0 20px;
  list-style-type: none;
`;
const NestedItem = styled.li<{ $isactive: boolean }>`
  position: relative;
  display: flex;
  padding: 12px 16px 12px 0;
  justify-content: start;
  align-items: center;
  align-self: stretch;
  color: ${(props) => (props.$isactive ? 'var(--mainBlue)' : '#262626')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background-color: rgba(214, 219, 237, 1);
    border-radius: 50%;
  }
`;
