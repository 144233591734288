'use client';

import React from 'react';
import styled from 'styled-components';

import Form from '../../features/Form/Form';

import { Container } from '@/components/Container';
import Overlay from '@/components/Overlay/Overlay';
import useEscClose from '@/hooks/useEscKey';
import { CloseIcon } from '@/public/icons';
import { devices } from '@/styles/breakpoints';

export interface BookDemoModalProps {
  onClose: () => void;
}

export default function BookDemoModal({ onClose }: BookDemoModalProps) {
  useEscClose({ onClose });

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  return (
    <Overlay>
      <Container>
        <Card
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            onSubmit(event)
          }
        >
          <CloseIconContainer>
            <CloseIcon onClick={onClose} />
          </CloseIconContainer>

          <Title>Book a Free Demo</Title>
          <Form isModal={true} />
        </Card>
      </Container>
    </Overlay>
  );
}

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  background: var(--modalBackground);
  border-radius: 0.6rem;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  overflow: scroll;

  margin-left: -16px;

  @media only screen and ${devices.desktop} {
    height: 100%;
    width: 550px;
    margin: 0 auto;
  }
  & .book-demo-modal {
    padding: 0;
  }
  & .controls {
    justify-content: end;
    & .control-cancel {
      display: block;
    }
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 2.5rem;

  svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: #23262f;
  font-family: var(--font--robboto);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 40px;
`;
