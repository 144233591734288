import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

const NavItem = ({ item, handleCloseMenu, isActivePage }) => {
  return (
    <NavItemWrapper onClick={handleCloseMenu} href={item.href}>
      <Title $isactive={isActivePage(item.href)}>{item.title}</Title>
    </NavItemWrapper>
  );
};

export const SimpleList = ({ items, title, handleCloseMenu, isActivePage }) => {
  return (
    <Wrapper>
      <ContentBlock>
        <GroupTitle>{title}</GroupTitle>
        <ListItems>
          {items?.map((item, idx) => (
            <NavItem
              key={idx}
              item={item}
              handleCloseMenu={handleCloseMenu}
              isActivePage={isActivePage}
            />
          ))}
        </ListItems>
      </ContentBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

const ContentBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  row-gap: 16px;
  padding: 20px 0;
`;
const ListItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  row-gap: 16px;
`;

const NavItemWrapper = styled(Link)`
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: hover 0.2s;
  &:hover p {
    color: var(--mainBlue);
    transition: hover 0.2s;
  }
`;

const GroupTitle = styled.p`
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: rgba(153, 153, 153, 1);
  text-transform: uppercase;
`;

const Title = styled.p`
  font-family: var(--font-roboto);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-weight: ${(props) => (props.$isactive ? '500' : '400')};
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.$isactive ? 'var(--mainBlue)' : 'rgba(77, 77, 77, 1)'};
`;
