import React, { FC } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import { ListWithImage } from './components/ListWithImage';
import { NestedList } from './components/NestedList';
import { SimpleList } from './components/SimpleList';

import { Container } from '@/components/Container';

interface IProps {
  sub_menu: [];
  isMenuOpen: boolean;
  activeSubMenu: number;
  handleClickSubMenu: (index: number) => void;
  fullmenu: boolean;
}

export const HeaderDropdown: FC<IProps> = ({
  isMenuOpen,
  sub_menu,
  fullmenu,
  handleCloseMenu,
  image,
  header_type,
  title,
  groupPath,
}) => {
  const path = usePathname();

  const isActivePage = (href) => path === href;

  const renderMenu = () => {
    if (header_type === 'list-with-image') {
      return (
        <ListWithImage
          items={sub_menu}
          image={image}
          title={title}
          handleCloseMenu={handleCloseMenu}
          isActivePage={isActivePage}
        />
      );
    }
    if (header_type === 'simple-list') {
      return (
        <SimpleList
          items={sub_menu}
          title={title}
          groupPath={groupPath}
          handleCloseMenu={handleCloseMenu}
          isActivePage={isActivePage}
        />
      );
    }
    if (header_type === 'nested-list') {
      return (
        <NestedList
          items={sub_menu}
          title={title}
          groupPath={groupPath}
          handleCloseMenu={handleCloseMenu}
          isActivePage={isActivePage}
        />
      );
    }
  };

  return (
    !!sub_menu?.length && (
      <SubMenu $fullmenu={fullmenu} hidden={!isMenuOpen}>
        <Border></Border>
        <Container>
          <SubMenuListItems style={{ width: fullmenu ? '100%' : 'auto' }}>
            {renderMenu()}
          </SubMenuListItems>
        </Container>

        {['nested-list', 'simple-list'].includes(header_type) && (
          <Footer>
            <StyledLink href={groupPath} onClick={handleCloseMenu}>
              <Container>{`See all ${title}`}</Container>
            </StyledLink>
          </Footer>
        )}
      </SubMenu>
    )
  );
};

const Footer = styled.div`
  padding: 12px 0;
  border-top: 1px solid rgba(237, 238, 241, 1);
`;

const StyledLink = styled(Link)`
  color: var(--mainBlue);
  &:hover {
    color: var(--mainBlue) !important;
    font-weight: 500;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(241, 242, 253, 1);
`;

const SubMenu = styled.div<{ hidden: boolean; $fullmenu: boolean }>`
  background-color: white;

  position: fixed;
  top: 60px;
  z-index: var(--zHeader);

  box-shadow: 0px 8px 8px 0px rgba(7, 30, 89, 0.04);

  display: flex;
  flex-direction: column;

  width: ${(p) => (p.$fullmenu ? '100%' : 'auto')};
  height: ${(p) => (p.$fullmenu ? 'auto' : 'auto')};
  left: ${(p) => (p.$fullmenu ? '0' : 'auto')};

  max-height: ${(p) => (p.hidden ? '0' : '500px')};
  /* transition: max-height 0.3s ease-in-out; */
  overflow: hidden;
`;

const SubMenuListItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
