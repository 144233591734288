'use client';

import React from 'react';
import styled from 'styled-components';

import { footerLinks } from './constants';

import { Container } from '@/components/Container';
import Link from '@/components/Link/Link';
import {
  AppStoreLogoSvg,
  FacebookLogoSvg,
  GooglePayLogoSvg,
  InstagramLogoSvg,
  LogoSvg,
} from '@/public/icons';
import { devices } from '@/styles/breakpoints';

export const Footer = () => {
  return (
    <FooterSection>
      <Wrapper>
        <NavFooter>
          {footerLinks.map((item, idx) => (
            <LinksBlock key={idx}>
              <LinksList>
                <LinkItem key={idx}>
                  <p>{item.title}</p>
                  <LinksListNested>
                    {item.items?.map((item, idx) => (
                      <LinkItemNested key={idx}>
                        <Link aria-label={item.title} href={item.path}>
                          {item.title}
                        </Link>
                      </LinkItemNested>
                    ))}
                  </LinksListNested>
                </LinkItem>
              </LinksList>
            </LinksBlock>
          ))}
          <SocialBlockMob>
            <Link href="/">
              <LogoSvg />
            </Link>
            <Address>
              16950 Via de Santa Fe Suite 5060-136 <br /> Rancho Santa Fe, CA
              92091
            </Address>

            <SocialIconsList>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                locale={false}
                href="https://www.instagram.com/getorcatec/"
              >
                <InstagramLogoSvg />
              </Link>

              <Link
                target="_blank"
                rel="noopener noreferrer"
                locale={false}
                href="https://www.facebook.com/people/GetOrcatec/100064292011425/"
              >
                <FacebookLogoSvg />
              </Link>
            </SocialIconsList>
            <PlatformIconWrapper>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                locale={false}
                href="https://play.google.com/store/apps/details?id=com.orcatec"
              >
                <GooglePayLogoSvg />
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                locale={false}
                href="https://apps.apple.com/us/app/orcatec/id1514423333"
              >
                <AppStoreLogoSvg />
              </Link>
            </PlatformIconWrapper>
          </SocialBlockMob>
        </NavFooter>
        <SubFooter>
          <div>{`Copyright © ${new Date().getFullYear()}`}</div>
          <TermsAndPolicyBlock>
            <Link
              href="/terms_and_conditions.pdf"
              // href={
              //   'https://s3.us-west-1.amazonaws.com/app.orcatec.storage/storage_61/media/A9Wp800kE9FXA0mj5SPgCaZr3hY4ByS0CAnR6IZf.pdf'
              // }
              target="_blank"
              rel="noopener noreferrer"
              locale={false}
              download
            >
              Terms of use
            </Link>
            <Link
              href="/privacy_policy.pdf"
              // href={
              //   'https://s3.us-west-1.amazonaws.com/app.orcatec.storage/storage_61/media/FXUtv8wixGdFVpmxzl9d3pqNnf10qPEtFYmjYjP0.pdf'
              // }
              target="_blank"
              rel="noopener noreferrer"
              locale={false}
              download
            >
              Privacy Policy
            </Link>
          </TermsAndPolicyBlock>
        </SubFooter>
      </Wrapper>
    </FooterSection>
  );
};

const FooterSection = styled.footer`
  background-color: #f6f7f9 !important;
  padding-top: 24px;
  @media only screen and ${devices.largeDesktop} {
    padding-top: 48px;
    padding-bottom: 10px;
  }
`;

const Wrapper = styled(Container)`
  /* background-color: #f6f7f9; */
`;

const SubFooter = styled.section`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-roboto);
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-top: 1px solid rgba(214, 219, 237, 1);
`;

const NavFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding-bottom: 24px;
  @media only screen and ${devices.largeDesktop} {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;
  }
`;

const SocialBlockMob = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 2;
`;

const SocialIconsList = styled.div`
  display: flex;
  gap: 16px;
  padding: 20px 0;
`;

const LinksBlock = styled.div`
  @media only screen and ${devices.largeDesktop} {
    min-width: 200px;
  }
`;

const LinksList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and ${devices.largeDesktop} {
    justify-content: space-between;
  }
`;
const LinkItem = styled.li`
  font-family: var(--font-roboto);
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const LinksListNested = styled.ul`
  padding: 0;
  list-style: none;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const LinkItemNested = styled.li`
  line-height: 0;
  & a {
    font-family: var(--font-roboto);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4d4d !important;
    transition: all 0.2s;
    &:hover {
      color: #4688f1 !important;
      transition: all 0.2s;
    }
  }
`;

const Address = styled.address`
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 16px;
`;

const TermsAndPolicyBlock = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const PlatformIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
