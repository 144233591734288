import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { devices } from '@/styles/breakpoints';

export default function BackToTopButton() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  });

  const jumpToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fragment>
      {show ? (
        <Wrapper>
          <Button onClick={jumpToTop}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.295 10.895C13.0216 11.1684 12.5784 11.1684 12.305 10.895L8 6.59001L3.69498 10.895C3.42161 11.1684 2.97839 11.1684 2.70503 10.895C2.43166 10.6217 2.43166 10.1784 2.70503 9.90508L7.50503 5.10508L8 4.61011L8.49498 5.10508L13.295 9.90508C13.5683 10.1784 13.5683 10.6217 13.295 10.895Z"
                fill="white"
              />
            </svg>
          </Button>
        </Wrapper>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
}

const Button = styled.button`
  display: inline-flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #206fed;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  outline: none;
  border: none;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 24px;
  right: 16px;

  z-index: 10;
  @media only screen and ${devices.desktop} {
    bottom: 60px;
    right: 60px;
  }
`;
