'use client';

import { GlobalStyle } from './GlobalStyles';
import StyledComponentsRegistry from './StyledComponentsRegistry';

const StyleThemeProvider = (props: React.PropsWithChildren) => {
  return (
    <StyledComponentsRegistry>
      <GlobalStyle />
      {props.children}
    </StyledComponentsRegistry>
  );
};

export default StyleThemeProvider;
