import React, { FC } from 'react';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import Link from '@/components/Link/Link';
import { ArrowIcon } from '@/public/icons';
import { NavItemType } from '@/types/navbar';
interface IProps {
  handleItemClick: () => void;
  isMenuOpen: boolean;
  title: string;
  sub_menu: [];
  type: NavItemType;
  href: string;
}

export const HeaderLink: FC<IProps> = ({
  isMenuOpen,
  handleItemClick,
  sub_menu,
  title,
  type,
  href,
}) => {
  const arrowStyle = {
    transform: `rotate(${isMenuOpen ? 180 : 0}deg)`,
    transition: 'all 0.2s',
    width: '16px',
    height: '16px',
  };

  const path = usePathname();

  const isActivePage = path === href || (path.includes(href) && href !== '/');

  const linkcolor = !isActivePage
    ? 'rgba(0, 0, 0, 0.6)'
    : 'rgba(70, 136, 241, 1)';

  return type === NavItemType.LINK ? (
    <Link aria-label={href} href={href}>
      <NavLink $isactive={!!isMenuOpen || isActivePage}>
        <p>{title}</p>
        {!!sub_menu?.length && (
          <ArrowIcon style={arrowStyle} color={linkcolor} />
        )}
      </NavLink>
    </Link>
  ) : (
    <Button variant="link" onClick={handleItemClick}>
      <NavLink $isactive={!!isMenuOpen || isActivePage}>
        <p>{title}</p>
        {!!sub_menu?.length && (
          <ArrowIcon style={arrowStyle} color={linkcolor} />
        )}
      </NavLink>
    </Button>
  );
};

const NavLink = styled.div<{ $isactive?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${(props) =>
    props.$isactive ? 'var(--mainBlue)' : 'rgba(0, 0, 0, 0.6)'};
  transition: color 0.2s;
  /* padding: 10px; */
  /* transition: all 0.2s; */
  /* background-color: ${(props) =>
    props.$isactive ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}; */
  border-radius: 6px;

  /* color: rgba(0, 0, 0, 0.6); */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  &:hover {
    color: var(--mainBlue);
    /* transform: scale(1.05); */
  }
  &:hover svg path {
    stroke: var(--mainBlue);
    /* transform: scale(1.05); */
  }
`;
