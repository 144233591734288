import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
// import { ArrowIcon } from '@/public/icons';

const NavItem = ({ item, handleCloseMenu }) => {
  return (
    <NavItemWrapper onClick={handleCloseMenu} href={item.href}>
      <Title
      // isActive={isActivePage(item.href)}
      >
        {item.title}
      </Title>
    </NavItemWrapper>
  );
};

export const NestedList = ({ items, handleCloseMenu }) => {
  return (
    <Wrapper>
      <WrapperInner>
        {items.map((item) => (
          <ContentBlock key={item.title}>
            <GroupTitle>{item?.title}</GroupTitle>
            <ListItems>
              {item.items.map((item, idx) => (
                <NavItem
                  key={idx}
                  item={item}
                  handleCloseMenu={handleCloseMenu}
                />
              ))}
            </ListItems>
          </ContentBlock>
        ))}
      </WrapperInner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const WrapperInner = styled.div`
  display: flex;
`;

const ContentBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  row-gap: 16px;
  padding: 20px 24px 20px 0;
`;
const ListItems = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  row-gap: 16px;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
   */
`;

const NavItemWrapper = styled(Link)`
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: hover 0.2s;
  &:hover p {
    color: var(--mainBlue);
    transition: hover 0.2s;
  }
`;

const GroupTitle = styled.p`
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: rgba(153, 153, 153, 1);
  text-transform: uppercase;
`;

const Title = styled.p`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: ${(props) => (props.isActive ? '500' : '400')};
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.isActive ? 'var(--mainBlue)' : 'rgba(77, 77, 77, 1)'};
`;
