import { createGlobalStyle } from 'styled-components';

// import { robotoLocal } from '../fonts/fonts';
import { robotoGoogle } from '../fonts/fonts';

export const GlobalStyle = createGlobalStyle`

:root {
  --font-roboto: ${robotoGoogle.style.fontFamily}, sans-serif; 
  --background:'white';
  --whiteColor: rgba(255, 255, 255, 1);
  --fontColorDark:#262626;
  --fontColorDarkBlue: #192D52;
  --fontColorDarkGrey: #4D4D4D;
  --modalBackground:rgba(255, 255, 255, 1);
  --navLinkColor: rgba(255, 255, 255, 0.7);
  --navLinkColorHover: rgba(255, 255, 255, 1);
  --navLinkColorDark: rgba(1, 1, 1, 0.7);
  --navLinkColorHoverDark: #0a0a0a;
  --borderGrey:rgba(214, 219, 237, 1);
  --mainBlue:#4666f1;
  --darkBlueText:rgba(25, 45, 82, 1);
  --greyColor:rgba(77, 77, 77, 1);
  --mainOrange:rgba(253, 164, 40, 1);
  --mainGreen:#00A643;
  --errorColor: rgba(241, 69, 61, 1);
  --inputBorder: rgba(214, 219, 237, 1);
  --modalOverlay: rgba(2, 3, 29, 0.55);
  --phone: 375px;
  --tablet: 768px;
  --desktop: 1024px;
  --largeDesktop: 1440px;
  --zDrawer:4;
  --zDrawerNested:5;
  --zHeader:3;
  --zNotFound:5;
  --z-modal:6;
  --size14:14px;
  --size20:20px;
  --size24:24px;
  --size28:28px;
  --size32:32px;
  --size40:40px;
  --size48:48px;
  --regular400:400;
  --semibold600:600;
  --bold700:700;

}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}
footer{
  margin-top:auto;
}

main{
  flex:1;
  display:flex;
  flex-direction:column;
}
& .main{
  flex:1;
}



.grecaptcha-badge { 
    visibility: hidden; 
  
  }


  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
    word-break: normal;
}  

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}


/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

ul,
ol{
  padding: 0;
  margin:0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
} 

html {
  -webkit-font-smoothing: antialiased;
  touch-action: manipulation;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  font-size: 62.5%;

  @media (max-width: 37.5em) {
    font-size: 50%;
  }

  @media (max-width: 48.0625em) {
    font-size: 55%;
  }

  @media (max-width: 56.25em) {
    font-size: 60%;
  }
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--font-roboto);
  background: var(--whiteColor);
  font-feature-settings: "kern";
  padding-top:64px;
}

svg {
  color: var(--text);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
/* img,
picture {
  max-width: 100%;
  display: block;
} */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  

}`;
