import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

const NavItem = ({ item, handleCloseMenu, isActivePage }) => {
  return (
    <NavItemWrapper onClick={handleCloseMenu} href={item.href}>
      <Title $isactive={isActivePage(item.href)}>{item.title}</Title>
      <Description className="descriprion">{item.description}</Description>
    </NavItemWrapper>
  );
};

export const ListWithImage = ({
  items,
  image,
  title,
  handleCloseMenu,
  isActivePage,
}) => {
  return (
    <Wrapper>
      <ContentBlock>
        <GroupTitle>{title}</GroupTitle>
        {items?.map((item, idx) => (
          <NavItem
            key={idx}
            item={item}
            handleCloseMenu={handleCloseMenu}
            isActivePage={isActivePage}
          />
        ))}
      </ContentBlock>
      <ImageBlock>
        <Image src={image} alt="image" />
      </ImageBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  padding: 20px 0;
`;

const ContentBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;
const ImageBlock = styled.div`
  width: 100%;
`;

const NavItemWrapper = styled(Link)`
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: hover 0.2s;
  padding: 12px 0;
  &:hover p:not(.descriprion) {
    color: var(--mainBlue);
    transition: hover 0.2s;
  }
  &:first-of-type {
    border-bottom: 1px solid rgba(237, 238, 241, 1);
    /* padding-bottom: 24px; */
  }
`;

const GroupTitle = styled.p`
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: rgba(153, 153, 153, 1);
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const Title = styled.p`
  font-family: var(--font-roboto);
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.$isactive ? 'var(--mainBlue)' : 'rgba(77, 77, 77, 1)'};
`;

const Description = styled.p`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(77, 77, 77, 1);
`;
