'use client';

import React, { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import styled from 'styled-components';

import { HeaderDropdown } from './components/HeaderDropdown/HeaderDropdown';
import { HeaderLink } from './components/HeaderLink/HeaderLink';

import Button from '@/components/Button/Button';
import { Container } from '@/components/Container';
import OriginalDrawer from '@/components/Drawer/Drawer';
import { HamburgerIcon } from '@/public/icons';
import { LogoSvg } from '@/public/icons';
import { NavItems } from '@/types/navbar';
import { IRoutePath } from '@/types/router';

type NavbarProps = { items: NavItems };

type SingleNavItem = {
  href: string;
  title: string;
  outlined: boolean | undefined;
  key: string;
};

export default function Navbar({ items }: NavbarProps) {
  const { toggle } = OriginalDrawer.useDrawer();

  return (
    <NavbarContainer>
      <Content>
        <HamburgerMenuWrapper onClick={toggle}>
          <HamburgerIcon color={'#324058'} aria-label="Toggle menu" />
        </HamburgerMenuWrapper>
        <LogoWrapper aria-label="logo" href="/">
          <LogoSvg color="#324058" />
        </LogoWrapper>

        <NavItemList>
          {items.map((singleItem) => (
            <NavItem key={singleItem.href} {...singleItem} />
          ))}
        </NavItemList>

        <ButtonWrapper>
          <Button
            onClick={() =>
              window.open('https://app.orcatec.com/auth/signin', 'e_blank')
            }
            variant="outlined"
          >
            <span>Sign in</span>
          </Button>
          <Button
            onClick={() =>
              window.open('https://app.orcatec.com/auth/signup', 'e_blank')
            }
            variant="secondary"
          >
            <span>Sign up</span>
          </Button>
        </ButtonWrapper>
      </Content>
    </NavbarContainer>
  );
}

function NavItem({
  title,
  href,
  type,
  outlined,
  sub_menu,
  header_type,
  image,
}: SingleNavItem) {
  const menuRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(0);

  const handleItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickSubMenu = (index: number) => {
    setActiveSubMenu(index);
  };

  const handleCloseMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
        setActiveSubMenu(0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <NavigationWrapper ref={menuRef} outlined={outlined}>
      <HeaderLink
        type={type}
        sub_menu={sub_menu}
        title={title}
        handleItemClick={handleItemClick}
        isMenuOpen={isMenuOpen}
        href={href}
      />

      <HeaderDropdown
        activeSubMenu={activeSubMenu}
        isMenuOpen={isMenuOpen}
        sub_menu={sub_menu}
        handleClickSubMenu={handleClickSubMenu}
        fullmenu={[
          IRoutePath.FEATURES,
          IRoutePath.COMPANY,
          IRoutePath.RECOURCES,
          IRoutePath.INDUSTRIES,
        ].includes(href)}
        handleCloseMenu={handleCloseMenu}
        header_type={header_type}
        image={image}
        title={title}
        groupPath={href}
      />
    </NavigationWrapper>
  );
}

const NavigationWrapper = styled.li<Partial<SingleNavItem>>`
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  margin-right: 24px;

  a {
    display: flex;
    text-decoration: none;
    //transition: color 0.2s;
    &:hover {
      color: var(--navLinkColorHover);
      // transition: color 0.2s;
    }
  }

  /* &:not(:last-child) {
    margin-right: 24px;
  } */
`;

const NavItemList = styled.ul`
  display: none;
  list-style: none;
  padding: 0;
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }
`;

const HamburgerMenuWrapper = styled.div`
  align-self: center;
  margin-top: 3px;
  margin-right: 10px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 1024px) {
    & button:last-child {
      display: none;
    }
  }
`;

const LogoWrapper = styled(NextLink)`
  display: flex;
  margin-right: auto;
  text-decoration: none;

  color: rgb(var(--logoColor));
  @media screen and (min-width: 1024px) {
    margin: 0;
  }
`;

const NavbarContainer = styled.nav`
  display: flex;
  position: sticky;
  top: 0;
  padding: 24px 0px;
  width: 100%;
  height: 64px;
  z-index: var(--zDrawer);
  background: white;
  backdrop-filter: blur(100px);
`;

const Content = styled(Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
  }
`;
