'use client';
import React from 'react';
import styled from 'styled-components';

import Navbar from './components/Navbar/Navbar';
import { navItem } from './constants';

const Header = () => {
  return (
    <HeaderWrapper>
      <Navbar items={navItem} />
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: var(--zHeader);
`;
